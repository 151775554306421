/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const constructUrl = (baseUrl, path) =>
  !baseUrl || !path ? null : `${baseUrl}${path}`;

function Seo({ description, lang, meta, keywords, title, imageUrl, imageAlt }) {
  const { site, ogImageDefault } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
            siteUrl
          }
        }
        ogImageDefault: file(relativePath: { eq: "profile-pic.jpg" }) {
          childImageSharp {
            fixed(height: 260, width: 260) {
              src
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  // Default image would be my profile pic.
  // TODO: pick a nice default image
  const defaultImageUrl = constructUrl(
    site.siteMetadata.siteUrl,
    ogImageDefault?.childImageSharp?.fixed?.src
  );

  const ogImageUrl = imageUrl || defaultImageUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        { property: "og:image", content: ogImageUrl },

        // If a post has an image, use the larger card.
        // Otherwise the default image is just
        // a small logo, so use the smaller card.
        {
          property: "twitter:card",
          content: imageUrl ? "summary_large_image" : "summary",
        },

        // Add image alt text
        // Falls back to default which describes the site logo
        {
          property: "twitter:image:alt",
          content: imageAlt || "amanexplains.com logo",
        },
        {
          name: "twitter:creator",
          content: site.siteMetadata.author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: "keywords",
                content: keywords.join(", "),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

Seo.defaultProps = {
  description: "",
  lang: "en",
  meta: [],
  keywords: [],
  imageUrl: "",
  imageAlt: "",
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default Seo;
